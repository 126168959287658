import { BOX_CLASSNAME } from "@/components/FormField/const";
import { Calendar, CalendarIcon, FormControl, Popover, PopoverContent, PopoverTrigger, cn } from "@jog/react-components";
import { dateTimeFormat } from "@jog/shared";
export const FormDatePicker = (props) => (<Popover>
        <PopoverTrigger asChild>
            <FormControl>
                <button className={cn(BOX_CLASSNAME, "w-full justify-between")}>
                    {props.selected ? dateTimeFormat(props.selected.toString(), { month: "numeric" }) : <></>}
                    <CalendarIcon className="ml-auto h-5 w-5 py-0.5"/>
                </button>
            </FormControl>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-3" align="start">
            <Calendar defaultMonth={props.selected} disabled={(date) => date > new Date() || date < new Date("1900-01-01")} classNames={{
        day: "hover:bg-green-main hover:text-grey-lighter",
        selected: "bg-green-main text-grey-lighter",
    }} {...props}/>
        </PopoverContent>
    </Popover>);
