import { FormItem as FormItemBase, FormLabel, FormMessage, cn, useI18n } from "@jog/react-components";
import * as React from "react";
export const FormItem = ({ required, label, children, className, ...props }) => {
    const { $t } = useI18n();
    return (<FormItemBase className={cn("space-y-2", className)} {...props}>
            {label ? (<FormLabel className="font-medium tracking-5 text-grey-dark">
                    {label}
                    {required ? <span className="ml-1 text-green-main">*</span> : <></>}
                </FormLabel>) : (<></>)}
            {children}
            <FormMessage Render={({ msg }) => <>{$t(msg)}</>}/>
        </FormItemBase>);
};
