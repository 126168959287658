import { lowerCaseLocale } from "@jog/shared";
import { DayFlag, DayPicker, UI } from "react-day-picker";
import { de, enGB, nl } from "react-day-picker/locale";
import { cn } from "./util";
const locales = { ["nl-nl"]: nl, ["nl-be"]: nl, ["de-de"]: de, ["en-us"]: enGB };
function Calendar({ className, classNames, ...props }) {
    return (<DayPicker mode="single" required={false} captionLayout="dropdown" locale={locales[lowerCaseLocale()] || enGB} className={className} classNames={{
            [UI.Root]: "z-10 relative",
            [UI.Dropdowns]: "flex space-x-2 h-6 items-center",
            [UI.Dropdown]: "",
            [UI.Nav]: "absolute right-0 flex h-6 items-center",
            [UI.CaptionLabel]: "hidden",
            [UI.Chevron]: "w-5 h-5 bg-transparent p-0 fill-current text-grey-default opacity-50 hover:opacity-100",
            [UI.MonthGrid]: "w-full border-collapse space-y-1 mt-4",
            [UI.Weekdays]: "flex",
            [UI.Weekday]: "w-8 mx-px font-normal text-xs text-black font-medium",
            [UI.Week]: "flex w-full mt-2",
            [UI.DayButton]: "w-full h-full",
            [DayFlag.today]: "",
            [DayFlag.outside]: "",
            [DayFlag.disabled]: "text-grey-light opacity-50",
            [DayFlag.hidden]: "invisible",
            ...classNames,
            [UI.Day]: cn("relative rounded-full m-px h-8 w-8 p-1 flex items-center text-grey-dark justify-center font-normal text-sm", classNames === null || classNames === void 0 ? void 0 : classNames[UI.Day]),
        }} {...props}/>);
}
Calendar.displayName = "Calendar";
export { Calendar };
