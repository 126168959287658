import { Slot } from "@radix-ui/react-slot";
import * as React from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useFormContext, useForm as useReactHookForm, } from "react-hook-form";
import { AlertBoxBase, usePending } from "../components";
import { Label } from "./Label";
import { cn } from "./util";
const ErrorContext = createContext({ submitError: null });
export const SubmitErrorProvider = ({ children, submitError }) => {
    return <ErrorContext.Provider value={{ submitError }}>{children}</ErrorContext.Provider>;
};
export const useSubmitError = () => useContext(ErrorContext);
const Form = ({ children, submitError, ...props }) => (<SubmitErrorProvider submitError={submitError}>
        <FormProvider {...props}>{children}</FormProvider>
    </SubmitErrorProvider>);
const useForm = (props) => {
    const [submitError, setSubmitError] = useState(null);
    const form = useReactHookForm(props);
    return {
        ...form,
        submitError,
        setSubmitError,
        handleSubmit: (fn) => form.handleSubmit(async (values) => {
            setSubmitError(null);
            return fn(values).catch((e) => setSubmitError(e));
        }),
    };
};
const FormFieldContext = React.createContext({});
const FormField = (props) => {
    return (<FormFieldContext.Provider value={{ name: props.name }}>
            <Controller {...props}/>
        </FormFieldContext.Provider>);
};
const useFormField = () => {
    const fieldContext = React.useContext(FormFieldContext);
    const itemContext = React.useContext(FormItemContext);
    const { getFieldState, formState } = useFormContext();
    const fieldState = getFieldState(fieldContext.name, formState);
    if (!fieldContext) {
        throw new Error("useFormField should be used within <FormField>");
    }
    const { id } = itemContext;
    return {
        id,
        name: fieldContext.name,
        formItemId: `${id}-form-item`,
        formDescriptionId: `${id}-form-item-description`,
        formMessageId: `${id}-form-item-message`,
        ...fieldState,
    };
};
const FormItemContext = React.createContext({});
const FormItem = React.forwardRef((props, ref) => {
    const id = React.useId();
    return (<FormItemContext.Provider value={{ id }}>
            <div ref={ref} {...props}/>
        </FormItemContext.Provider>);
});
FormItem.displayName = "FormItem";
const FormLabel = React.forwardRef(({ className, ...props }, ref) => {
    const { formItemId } = useFormField();
    return <Label ref={ref} className={cn(className)} htmlFor={formItemId} {...props}/>;
});
FormLabel.displayName = "FormLabel";
const FormControl = React.forwardRef((props, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField();
    return (<Slot ref={ref} id={formItemId} aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`} aria-invalid={!!error} {...props}/>);
});
FormControl.displayName = "FormControl";
const FormDescription = React.forwardRef((props, ref) => {
    const { formDescriptionId } = useFormField();
    return <p ref={ref} id={formDescriptionId} {...props}/>;
});
FormDescription.displayName = "FormDescription";
const FormMessage = React.forwardRef(({ className, children, Render, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error ? <Render msg={String(error === null || error === void 0 ? void 0 : error.message)}/> : children;
    if (!body)
        return <></>;
    return (<p ref={ref} id={formMessageId} className={cn("text-xs text-red-error", className)} {...props}>
            {body}
        </p>);
});
FormMessage.displayName = "FormMessage";
const FormPending = () => {
    const { formState } = useFormContext();
    const { setPending } = usePending();
    useEffect(() => {
        setPending(formState.isSubmitting || formState.isLoading);
    }, [formState.isLoading, formState.isSubmitting, setPending]);
    return <></>;
};
const useFormSuccess = () => {
    const { formState } = useFormContext();
    const { submitError } = useSubmitError();
    return useMemo(() => {
        if (formState.isSubmitting || formState.isLoading || !formState.isSubmitted)
            return false;
        if (!formState.isSubmitSuccessful)
            return false;
        if (submitError)
            return false;
        return true;
    }, [formState.isLoading, formState.isSubmitSuccessful, formState.isSubmitted, formState.isSubmitting, submitError]);
};
const FormSuccess = ({ msg }) => {
    const { formState } = useFormContext();
    const { submitError } = useSubmitError();
    if (formState.isSubmitting || formState.isLoading || !formState.isSubmitted)
        return <></>;
    if (!formState.isSubmitSuccessful)
        return <></>;
    if (submitError)
        return <></>;
    return <AlertBoxBase type="normal" text={msg}/>;
};
const FormSuccessEffect = ({ onSuccess }) => {
    const success = useFormSuccess();
    useEffect(() => {
        if (success)
            onSuccess();
    }, [onSuccess, success]);
    return <></>;
};
const FormFail = ({ msg }) => {
    const { formState } = useFormContext();
    const { submitError } = useSubmitError();
    if (formState.isSubmitting || formState.isLoading || !formState.isSubmitted)
        return <></>;
    if (!submitError)
        return <></>;
    return <AlertBoxBase type="error" text={msg}/>;
};
export { Form, FormControl, FormDescription, FormFail, FormField, FormItem, FormLabel, FormMessage, FormPending, FormSuccess, FormSuccessEffect, useForm, useFormField, useFormSuccess, };
