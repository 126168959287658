import { ChevronDown, FormControl, Select, SelectContent, SelectIcon, SelectItem, SelectTrigger, SelectValue, cn, } from "@jog/react-components";
import { BOX_CLASSNAME } from "./const";
export const FormSelect = ({ className, options, placeholder, ...props }) => (<Select {...props}>
        <FormControl>
            <SelectTrigger className={cn(BOX_CLASSNAME, "w-full justify-between text-left data-[placeholder]:font-normal data-[placeholder]:text-[#808080]", className)}>
                <SelectValue placeholder={placeholder}/>
                <SelectIcon asChild>
                    <ChevronDown className="group pointer-events-none text-black" height={20} width={20} aria-hidden="true" strokeWidth={2}/>
                </SelectIcon>
            </SelectTrigger>
        </FormControl>
        <SelectContent position="popper" className="relative z-[100] max-h-[300px] min-w-[var(--radix-select-trigger-width)] overflow-hidden border border-gray-medium bg-white text-black data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1">
            {options.map((item) => (<SelectItem className="relative flex w-full cursor-pointer select-none items-center py-1.5 pl-2 pr-8 text-sm outline-none focus:bg-gray-bg" value={item.value} key={item.value}>
                    {item.text}
                </SelectItem>))}
        </SelectContent>
    </Select>);
