export const CalendarIcon = (props) => (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <g clipPath="url(#clip0_35_603)">
            <path d="M14.3437 16H1.65631C1.63244 15.991 1.60935 15.9777 1.58431 15.9738C1.06664 15.8919 0.660879 15.6299 0.350199 15.212C0.157296 14.9528 0.0680834 14.652 0 14.3426C0 10.5278 0 6.71252 0 2.89766C0.0101734 2.8632 0.0230858 2.82913 0.0297376 2.79388C0.156122 2.14378 0.524321 1.68244 1.11946 1.40047C1.46419 1.23716 1.83395 1.23128 2.20528 1.23951C2.3434 1.24264 2.48152 1.2399 2.63021 1.2399V0H3.88232V1.23285H12.1271V0.00117489H13.3792V1.24029C13.6183 1.24029 13.8468 1.22697 14.0733 1.24421C14.2944 1.26105 14.5233 1.27867 14.7315 1.34799C15.5164 1.60842 15.9992 2.31805 15.9992 3.18081C16 6.8069 15.9992 10.433 15.9992 14.0595C15.9992 14.0908 15.9992 14.1221 15.9984 14.1531C15.9757 14.7695 15.7191 15.2633 15.2288 15.6374C14.9654 15.8379 14.6595 15.9303 14.3433 16H14.3437ZM1.25015 5.87561V6.04166C1.25015 8.69925 1.25015 11.3568 1.25015 14.0144C1.25015 14.511 1.49001 14.7491 1.98929 14.7491C5.99408 14.7491 9.99848 14.7491 14.0033 14.7491C14.5131 14.7491 14.7498 14.5138 14.7502 14.0066C14.7502 11.3541 14.7502 8.7016 14.7502 6.0491V5.87522H1.25015V5.87561ZM3.87371 3.73105H2.62121V2.49154C2.38722 2.49154 2.16498 2.49076 1.94233 2.49154C1.50566 2.49389 1.25172 2.74493 1.25015 3.17885C1.24859 3.61121 1.24937 4.04396 1.25015 4.47632C1.25015 4.52096 1.25602 4.56561 1.25837 4.60516H14.7479C14.7479 4.09252 14.7542 3.59241 14.7455 3.0923C14.7405 2.79976 14.537 2.5448 14.2494 2.51308C13.9638 2.48136 13.6723 2.50603 13.3663 2.50603V3.7334H12.1196V2.50447H3.87371V3.73144V3.73105Z" fill="#4B4B4B"/>
            <path d="M3.61183 8.42939H2.38672V7.19263H3.61183V8.42939Z" fill="#4B4B4B"/>
            <path d="M4.88574 7.18921H6.11672V8.42049H4.88574V7.18921Z" fill="#4B4B4B"/>
            <path d="M8.61389 8.42586H7.3833V7.19458H8.61389V8.42586Z" fill="#4B4B4B"/>
            <path d="M11.117 7.19116V8.42088H9.88525V7.19116H11.117Z" fill="#4B4B4B"/>
            <path d="M13.6189 7.19336V8.42347H12.3828V7.19336H13.6189Z" fill="#4B4B4B"/>
            <path d="M3.61711 10.9277H2.38574V9.69287H3.61711V10.9277Z" fill="#4B4B4B"/>
            <path d="M6.11389 10.9276H4.8833V9.69629H6.11389V10.9276Z" fill="#4B4B4B"/>
            <path d="M7.38574 9.69043H8.61633V10.9217H7.38574V9.69043Z" fill="#4B4B4B"/>
            <path d="M11.1178 9.69238V10.9229H9.88525V9.69238H11.1178Z" fill="#4B4B4B"/>
            <path d="M13.6146 10.9268H12.3828V9.69556H13.6146V10.9268Z" fill="#4B4B4B"/>
            <path d="M2.38086 13.4238V12.1941H3.61693V13.4238H2.38086Z" fill="#4B4B4B"/>
            <path d="M4.88574 12.1921H6.11672V13.4234H4.88574V12.1921Z" fill="#4B4B4B"/>
            <path d="M8.61633 13.4288H7.38574V12.1948H8.61633V13.4288Z" fill="#4B4B4B"/>
            <path d="M11.1138 13.429H9.88281V12.1978H11.1138V13.429Z" fill="#4B4B4B"/>
        </g>
        <defs>
            <clipPath id="clip0_35_603">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>);
