import { BOX_CLASSNAME } from "@/components/FormField/const";
import { useEyes } from "@/components/Icon/Eyes";
import { FormControl, cn } from "@jog/react-components";
import { forwardRef } from "react";
export const FormInput = forwardRef(({ className, ...props }, ref) => (<div className={cn(BOX_CLASSNAME, className)}>
            <FormControl>
                <input className="w-full placeholder:text-[#808080] focus:outline-0 disabled:opacity-50" ref={ref} {...props}/>
            </FormControl>
        </div>));
FormInput.displayName = "FormInput";
export const PasswordInput = forwardRef(({ className, ...props }, ref) => {
    const { Icon, show, setShow } = useEyes();
    return (<div className={cn(BOX_CLASSNAME, className, "items-center justify-between")}>
                <FormControl>
                    <input type={show ? "text" : "password"} className="w-full focus:outline-0" ref={ref} {...props}/>
                </FormControl>
                <button type="button" className={cn("ml-2 flex-shrink-0", !props.value ? "hidden" : "")} onClick={() => setShow((v) => !v)}>
                    <Icon height={20} width={20} className="text-green-main"/>
                </button>
            </div>);
});
PasswordInput.displayName = "PasswordInput";
